import { useReducer, Dispatch, Reducer } from 'react';
import { setStorageItem } from 'app/sharedServices/storage/storage';

export enum ActionTypes {
  SET_FLOW = '@@/flowStateContext/SET_FLOW',
  CLEAR_FLOW = '@@/flowStateContext/CLEAR_FLOW',
  SET_FORM_DATA = '@@/flowStateContext/SET_FORM_DATA',
  SET_INTERACTION_TYPE = '@@/flowStateContext/SET_INTERACTION_TYPE',
  SET_SELECTED_TIME = '@@/flowStateContext/SET_SELECTED_TIME',
  SET_BOOKING_ID = '@@/flowStateContext/SET_BOOKING_ID',
  SET_CONFIRMED_BOOKING = '@@/flowStateContext/SET_CONFIRMED_BOOKING',
}

export interface RJSFFormData {
  personalDetails: {
    email?: string;
    firstName?: string;
    lastName?: string;
    phone?: string;
    postcode?: string;
    lgaPid?: string;
    lgaName?: string;
    lgaShortName?: string;
    address?: string;
    addressLat?: number;
    addressLong?: number;
    addressSuburb?: string;
  };
  appointmentDetails: {
    interpreterLanguage?: string;
    needInterpreter?: string;
    selectedTime?: string;
    bookingLocation?: string;
    bookingId?: string;
    interactionType?: 'Phone call' | 'In-person' | 'Video conference';
    isCustomerAddress?: boolean;
    bookingSource?: string;
    bookingSourceId?: string;
    bookMe?: string;
  };
  questionnaire: {
    // Career General Agency
    employmentType?: string;
    currentJobTitle?: string;
    currentIndustry?: string;
    highestQualification?: string;
    highestQualificationName?: string;
    accessedCareerAdviceInPast?: string;
    accessedSkillsHubService?: string;

    // School Student Consultation Agency
    isStudent?: 'Yes' | 'No';
    studentFirstName?: string;
    studentLastName?: string;
    preferredName?: string;
    currentSchoolYear?: string;
    rollCallRoomName?: string;
  };
  notification: {
    requireSmsReminder?: boolean;
  };

  // this is used for the opt in sms option on the review page, boolean
  // modified in landing page and ProfileConnectListener
  showSendCustomerSms: boolean;
  isVerifiedCsr: boolean;
  overrideLeadTime: boolean;
  isGuestBooking: boolean;
  extraConfirmationContent?: boolean;
  initialBookingTime?: string;
  initialBooking?: CommonTypes.ConfirmedBooking;
}

export type FlowState = {
  formData: Partial<RJSFFormData>;
  confirmedBooking?: CommonTypes.ConfirmedBooking;
};

export const initialState: FlowState = {
  formData: {},
  confirmedBooking: null,
};

const setFlowState = (state, action) => {
  const data = {
    ...state,
    ...action.payload,
  };
  setStorageItem('flowState', data, 'session');
  return data;
};

const clearFlowState = () => {
  const data = initialState;
  setStorageItem('flowState', data, 'session');
  return data;
};

const setFormDataState = (state, action) => {
  const data = {
    ...state,
    formData: {
      ...state.formData,
      ...(action.payload?.formData || state.formData),
    },
  };
  setStorageItem('flowState', data, 'session');
  return data;
};

const setConfirmedBookingState = (state, action) => {
  const data = {
    ...state,
    confirmedBooking: action.payload?.confirmedBooking || state.confirmedBooking,
  };
  setStorageItem('flowState', data, 'session');
  return data;
};

export const reducer: Reducer<FlowState, Action> = (state, action): FlowState => {
  const reducers = {
    [ActionTypes.SET_FLOW]: () => setFlowState(state, action),
    [ActionTypes.CLEAR_FLOW]: () => clearFlowState(),
    [ActionTypes.SET_FORM_DATA]: () => setFormDataState(state, action),
    [ActionTypes.SET_CONFIRMED_BOOKING]: () => setConfirmedBookingState(state, action),
  };

  return reducers[action.type]() || state;
};

export const useStore = (): [FlowState, Dispatch<Action>] => useReducer(reducer, initialState);
